import gsap from 'gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import "./Head.css";


const Head = () => {
  // state for typing effect
    const [wordIndex, setWordIndex] = useState(0);
    const [text, setText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);

// for gsap animation
  const comp = useRef(null)

  useLayoutEffect(() => {
    let ctx = gsap.context(()=>{
      const t1 = gsap.timeline()
      t1.from(["#title1", "#title2", "#title3"],{
        opacity:0,
        y:"+=30",
        stagger:0.5,
      })
      
    }, comp)

    return () => ctx.revert()
  }, []);

  // typing effect function
      useEffect(() => {
      const subheadings = [
        "Drive Growth and Innovation: HR Solutions for Tomorrow's Success.",
        "Expert HR. Crafted for Your Unique Needs.",
        "HR Challenges Solved. Focus on What Matters.",
      ];

      function typing() {
        // Current word
        const currentWord = subheadings[wordIndex];
        // Determine the function to be performed
        const shouldDelete = isDeleting ? 1 : -1;
        // Create the new text
        setText(current => currentWord.substring(0, current.length - shouldDelete));
        // Determine if this word is complete
        if (!isDeleting && text === currentWord) {
          // Make a pause at the end
          setTimeout(() => setIsDeleting(true), 500);
        } else if (isDeleting && text === '') {
          setIsDeleting(false);
          // Move to the next word
          setWordIndex((current) => (current + 1) % subheadings.length);
        }
      }
    
      const timer = setTimeout(typing, isDeleting ? 50 : 50);
      // Cleanup function to clear the timeout
      return () => clearTimeout(timer);
    // Add dependencies to the dependency array
    }, [wordIndex, isDeleting, text]);
 


  return (
        <header id='home' ref={comp}>
          <div className='hero-text'>
            <h1 id='title1'>Strategic HR.</h1>
            <h1 id='title2'>Empowering Businesses.</h1>
            <h1 id='title3'>Inspiring People.</h1>
            <div>
              <p >{text}</p>
            </div>
            <a href="#contact" className='btn dark-btn'>Get Started</a>
          </div>

          <div className='hero-image'>
            <img src="/assets/hero-banner.png" alt="" />
          </div>
        </header>
  )
}

export default Head