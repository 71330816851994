import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";
import Notiflix from "notiflix";


const ContactForm = () => {
  const formRef = useRef();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    organization: "",
    role: "",
    service: "",
    message: "",
    team: "",
    media: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value  });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // from emailjs
    emailjs
      .sendForm(
        "service_grha9jg",
        "template_5dkltef",
        formRef.current,
        "r0ACR9TGxEfYwcEZc"
      )
      .then(
        (result) => {
        Notiflix.Notify.success("Message sent successfully");
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        Notiflix.Notify.success("Sorry, An error occurred");
          
        }
      );
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      organization: "",
      role: "",
      service: "",
      message: "",
      team: "",
      media: "",
    });
  };
  return (
    <div className="form-background" id="contact">
      <h3>Contact Us</h3>
      <p>Schedule a free consultation with us today.</p>

      <form action="" ref={formRef} onSubmit={handleSubmit}>
        <div className="form-control">
          <label htmlFor="fullName">
            Full Name <span>(Required)</span>
          </label>
          <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} placeholder="Full Name" required />
        </div>
        <div className="form-control">
          <label htmlFor="email">
            Company Email <span>(Required)</span>
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange} 
            placeholder="Type your email"
            required
          />
        </div>
        <div className="form-control">
          <label htmlFor="phone">
            Phone <span>(Required)</span>
          </label>
          <input
            type="number"
            name="phone"
            value={formData.phone} 
            onChange={handleChange} 
            placeholder="Type phone number"
            required
          />
        </div>
        <div className="form-control">
          <label htmlFor="organization">
            Organization <span>(Required)</span>
          </label>
          <input type="text" name="organization" value={formData.organization} onChange={handleChange}  required />
        </div>
        <div className="form-control">
          <label htmlFor="role">
            Job Role <span>(Required)</span>
          </label>
          <select name="role" id="role" value={formData.role} onChange={handleChange}  required>
              <option value="">Select </option>
            <option value="CSuite">C-Suite</option>
            <option value="Head HR">Head of HR</option>
            <option value="Head Learning">Head of Learning</option>
            <option value="Head Outsourcing">Head of Outsourcing </option>
            <option value="Head Recruitment">Head of Recruitment </option>
            <option value="HR Professional">HR Professional </option>
            <option value="Business Manager">Business Manager </option>
            <option value="Team Lead">Team Lead</option>
            <option value="Team Member">Team Member</option>
            <option value="Self-employed">Self-employed</option>
            <option value="Other">Other</option>
            <option value="Manager">Manager</option>
            <option value="HR Director">HR Director</option>
            <option value="HR Manager">HR Manager</option>
            <option value="Group Head HRA">Group Head HRA</option>
            <option value="HR Leader">HR Leader</option>
            <option value="Compliance">Compliance</option>
            <option value="Head, Learning & Development">Head, Learning & Development</option>
            <option value="Lecturer">Lecturer</option>
            <option value="Senior HR Adviser">Senior HR Adviser</option>
            <option value="Head Strategy">Head Strategy</option>
            <option value="Public Relations">Public Relations</option>
            <option value="HR_Recruitment">HR-Recruitment</option>
            <option value="Managing Consultant">Managing Consultant</option>
            <option value="Veterinary">Veterinary</option>
            <option value="HR Business Partner">HR Business Partner</option>
            <option value="Child Development Director">Child Development Director</option>
            <option value="Professor">Professor</option>
          </select>
        </div>
        <div className="form-control">
          <label htmlFor="service">
            What services are you interested in? <span>(Required)</span>
          </label>
          <select name="service" id="service" value={formData.service} onChange={handleChange} required>
            <option value="">Select </option>
            <option value="Sales and digital marketing force outsourcing">
              Sales and Digital Marketing Force Outsourcing
            </option>
            <option value="Engineering and technical staff outsourcing">
              Engineering and Technical Staff Outsourcing
            </option>
            <option value="Admin and Operations Management">Admin and Operations Management </option>
            <option value="IT Staff Outsourcing">IT Staff Outsourcing </option>
            <option value="senior staff ousourcing">Experienced and Senior Staff Outsourcing </option>
            <option value="HR Process Outsourcing">HR Process Outsourcing </option>
            <option value="Payroll Management">Payroll Management </option>
            <option value="Background Check">Background Check</option>
            <option value="Outwork">Outwork</option>
            <option value="One-off Recruitment and placement services">One-off Recruitment and placement Services</option>
            <option value="HR Function Audit">HR Function Audit</option>
            <option value="Staff Audit">Staff Audit</option>
          </select>
        </div>
        <div className="form-control">
          <label htmlFor="message">
            Brief overview of how we can be of help <span>(Required)</span>
          </label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        {/* flex this field */}
        <div className="flexed-field">
          <div className="form-control">
            <label htmlFor="team">Company Size</label>
            <select name="team" id="team" value={formData.team} onChange={handleChange}required>
              <option value="">Select </option>
              <option value="1-10">1-10 employees</option>
              <option value="11-50">11-50 employees</option>
              <option value="51-200">51-200 employees</option>
              <option value="201-500">201-500 employees</option>
              <option value="501-1000">501-1000 employees</option>
              <option value="1001_5000">1001-5000 employees</option>
              <option value="5001-10000">5001-10,000 employees</option>
              <option value="10001+">10,001+ employees</option>
            </select>
          </div>

          <div className="form-control">
            <label htmlFor="media">
              How did you hear about us? <span>(Required)</span>
            </label>
            <select name="media" id="media" value={formData.media} onChange={handleChange} required>
              <option value="">Select </option>
              <option value="linkedin">Linkedin </option>
              <option value="instagram">Instagram </option>
              <option value="facebook">Facebook </option>
              <option value="google search">Google Search </option>
              <option value="X"> X </option>
              <option value="sponsored ad"> Sponsored Ad </option>
              <option value="referral"> Referral </option>
              <option value="others"> Others </option>
            </select>
          </div>
        </div>
        <button className="btn primary-btn form-btn">Schedule Now</button>
      </form>
    </div>
  );
};

export default ContactForm;
