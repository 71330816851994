import React from 'react'
import './Brand.css'

const brands = ["/assets/ups-logo.svg", "/assets/g-african-industries-logo.svg", "/assets/gsk-logo.svg", "/assets/axxela-logo.svg", "/assets/the-place-logo.svg", "/assets/ets-logo.svg","/assets/credpal-logo.svg", "/assets/fibre-one-90.svg","/assets/sahara-icon.svg", "/assets/page-logo.svg"]

const Brand = () => {

  return (
    <div className="brand">
        <h3>They Trust Us</h3>
        <p className='sub-head'>and so can you</p>
        <div className='brand-container'>
            {brands.map((brand, index)=>{
                return (
                    <div key={index}>
                        <img src={brand} alt={brand}/>
                    </div>
                )
            })}
        
        </div>
    </div>

  
  )
}

export default Brand