import React from 'react'
import './Footer.css'

const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()

  return (
    <footer>
        <div className="top">
            {/* col1 */}
            <img src="/assets/footer-assets/careers-verified-white.svg" alt="" className='footer-logo' />
            
            {/* col2 */}
            <ul className='footer-nav'>
                <li><a href="#home">Home</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#about">About</a></li>
            </ul>

            {/* col3 */}
      
            <ul className="contact-info">
                <li>
                    <img src="/assets/footer-assets/location-icon.svg" alt="location" /> 
                    <small >
                        <span>4th floor Rivers House, 83 Ralph Shodeinde Street, Central Business District, Abuja.</span> 
                        <span>2nd floor, Mulliner Towers, 39 Alfred Rewane Road, Ikoyi, Lagos.</span> 
                    </small>
                </li>

                <li>
                    <img src="/assets/footer-assets/round-phone-24px.svg" alt="phone" /> 
                    <small>
                        <span> +234-0905-877-1713</span>
                        <span> +234-0815-608-2288</span>  
                    </small>
                </li>

                <li>
                    <img src="/assets/footer-assets/majesticons_mail.svg" alt="email" /> 
                    <a href="mailto:Info@careersverified.net">Info@careersverified.net</a>
                </li>
            </ul>

             {/*col4  */}
            <div className='blank-space'> 
            </div>
                {/* col5 */}
            <div className='socials'>
                <small>Social Media</small>
                <a href="https://www.facebook.com/profile.php?id=61557775178838&mibextid=ZbWKwL" target='_blank' rel='noreferrer'> 
                    <img src="/assets/footer-assets/facebook.svg" alt="" /> 
                </a>
                <a href="https://x.com/Careersveri?s=09" target='_blank' rel='noreferrer'> 
                    <img src="/assets/footer-assets/mage_x.svg" alt="" /> 
                </a>
                <a href="https://www.linkedin.com/company/careersverified/" target='_blank' rel='noreferrer'> 
                    <img src="/assets/footer-assets/linkedin black.1.svg" alt="" /> 
                </a>
                <a href="https://youtube.com/@CareersVerified?si=RQK9TsfRHGK5fv5-" target='_blank' rel='noreferrer'> 
                    <img src="/assets/footer-assets/youtube color.1.svg" alt="" /> 
                </a>
                <a href="https://www.instagram.com/careersverified1?igsh=MWRnM245OThlNGk5Zg==" target='_blank' rel='noreferrer'>        
                    <img src="/assets/footer-assets/instagram black.1.svg" alt="" />
                </a>
            </div>
        </div>
        <hr/>
        <div className="bottom">
            <small> &copy; Copyright {year}, All Rights Reserved</small>
        </div>
    </footer>
  )
}

export default Footer