const sliderData = [
    {
        id: 1,
        service: "Outsourcing",
        img: "/assets/outsourcing-logo.svg",
        text: "Global talent pool, simplified. Outsource HR & find the perfect fit, every time."
    },
    {
        id: 2,
        service: "Resourcing",
        img: "/assets/resourcing-logo.svg",
        text: "Future-Proof Your Workforce: We source strategic talent, today. Build a team that fuels innovation for tomorrow."
    },
    {
        id: 3,
        service: "Executive Placement",
        img: "/assets/executive-placement-logo.svg",
        text: " Level Up Your Leadership: We source the visionaries who disrupt and define success. Don't settle for average, get extraordinary."
    },
    {
        id: 4,
        service: "Assessment and Development Center",
        img: "/assets/accessment-dev-center-logo.svg",
        text: " Transform Potential into Performance: Assessments that diagnose and develop. Invest in your people, invest in growth."
    },
    {
        id: 5,
        service: "Experienced Hire",
        img: "/assets/experienced-hire-logo.svg",
        text: "Growth on Autopilot: We handle the talent hunt, you focus on scaling your business. Let go of the stress, embrace the possibilities. "
    },
    {
        id: 6,
        service: "Graduate Recruitment",
        img: "/assets/graduate-recruit-logo.svg",
        text: "Shape the Future, Together: Invest in the next generation. Attract brilliant graduates, shape your company's legacy."
    },
    {
        id: 7,
        service: "In-Company/ Customised Programmes",
        img: "/assets/content-digitalization-logo.svg",
        text: "Spark Innovation, Together: Ignite new ideas, fuel growth. Customized workshops, energizing retreats. "
    },
    {
        id: 8,
        service: "Open Programmes",
        img: "/assets/open-programes-logo.svg",
        text: "Level Up & Network: Sharpen your edge, connect with peers. Interactive workshops in Finance, Leadership & Productivity. Spark growth, unlock your potential."
    },
    {
        id: 9,
        service: "E-Learning",
        img: "/assets/e-learning-logo.svg",
        text: "Learning Reimagined: Turn your content into engaging e-learning. Custom solutions, brilliant results. No more boring training! "
    },
    

]

export default sliderData;

    



