import React,{useEffect, useRef, useState} from 'react'
import DesktopNavbar from './DesktopNavbar'
import MobileNavbar from './MobileNavbar'
import './Navbar.css';

const Navbar = () => {
    const navbarRef = useRef(null);
    const[isOpen, setIsOpen] = useState(false);
    
    // to toggle menu
    const toggleMenu=()=>{
      setIsOpen(!isOpen)
    }

    const closeMenu=()=>{
      setIsOpen(false)
    }
  // to monitor scroll and manipulate the nav bar in the dom
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 0) {
      navbarRef.current.classList.add('navbar--scrolled');
      navbarRef.current.querySelector('.nav-wrapper').classList.add('nav-wrapper--scrolled');
    } else {
      navbarRef.current.classList.remove('navbar--scrolled');
      navbarRef.current.querySelector('.nav-wrapper').classList.remove('nav-wrapper--scrolled');
    }
  };

  // Attach scroll event listener on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <nav className="navbar" ref={navbarRef}>
      <div className="nav-wrapper">
        <div className='company-logo'>
            <img src="/assets/careers-verified-logo.svg" alt="careers-verified" />
        </div>
        
        <DesktopNavbar/>
        <img src="/assets/menu-btn.svg" alt="menu" className='menu' onClick={toggleMenu} />

        <MobileNavbar openMenu={isOpen} closeMenu={closeMenu}/>
        <>
        <a href="#contact" className='btn primary-btn desktop-primary-btn'>
            <img src="/assets/tiny-profile-icon.svg" alt="icon" />
            <span>Become a Client</span>
        </a>
        </>
    </div>
    </nav>
  )
}

export default Navbar