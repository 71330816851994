import React from 'react'
import './Testimonials.css'
import Slider from "react-slick";

const testimonialData = [
    {
        head: "Building Trust",
        sub_head: "A fulfilling experience cannot be contained",
        testimony: "We are glad to have partnered with Careers Verified. They are incredibly helpful and we had a great experience   using their HR services, i would definitely recommend their services to any organization.",
        company_name: "Page Financials",
        image: "/assets/page-fiancials.png"
    },
    {
        head: "Building Trust",
        sub_head: "A fulfilling experience cannot be contained",
        testimony: "Careers Verified is one of the most competent and professional HR Consulting firms in Nigeria. We are extremely satisfied with the services they provide. There is never a question every detail will be attended to your satisfaction. We certainly look forward to an ever-expanding relationship.",
        company_name: "African Industries Group",
        image: "/assets/page-fiancials.png"
    },

]

const Testimonials = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    autoplay:true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="testimonial-container">

    <Slider {...settings} className='main-slide'>
        {testimonialData.map((testimonial, index)=>{
            return(
                <div className='testimonial'>
                    <div className="testimonial-image">
                        <img src={testimonial.image} alt="" />
                    </div>
                    <div className="testimonial-text">
                        <h4>{testimonial.head} </h4>
                        <h5>{testimonial.sub_head} </h5>
                        <p>"{testimonial.testimony}" </p>
                        <small>{testimonial.company_name} </small>
                    </div>
                </div>
        
            )
        
        })}
    </Slider>
    </div>
  )
}

export default Testimonials