import React from 'react'
import NavLinks from './NavLinks'

const MobileNavbar = ({openMenu, closeMenu}) => {
  return (
    <nav className={openMenu? "mobile-nav isActive": "mobile-nav"}>
        <div className="close-menu">
            <img src="/assets/close-btn.svg" alt="close menu" onClick={closeMenu} />
        </div>

        <NavLinks closeMenu={closeMenu}/>

        <a href="#contact" className='btn primary-btn mobile-btn' onClick={closeMenu}>
          <img src="/assets/tiny-profile-icon.svg" alt="icon" />
          <span>Become a Client</span>
        </a>
    </nav>
  )
}

export default MobileNavbar