import React from 'react'
import NavLinks from './NavLinks'

const DesktopNavbar = () => {
  return (
    <nav className='desktop-nav'>
        <NavLinks/>
    </nav>
  )
}


export default DesktopNavbar