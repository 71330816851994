import React from 'react'
import sliderData from '../slider/sliderData';
import "./MobileCards.css"

const MobileCards = () => {
  return (
    <div className='mobile-card-container'>
        {sliderData.map((item) => {
          const { text, id, service, img } = item;
          return (
            <div key={id} className={`slide-card ${id === 1 ? 'highlighted' : ''}`}>
              <img src={img} alt={img} />
              <h4>{service} </h4>
              <p> {text}</p>
            </div>
          );
        })}
    
    </div>
  )
}

export default MobileCards