
    const counterData= [
        {
            label: "Capacity learning facilities",
            number: 100,
            cName:"counter first-count",
        },
        {
            label: "Clients",
            number: 20,
           cName:"counter second-count",
        },
        {
            label: "Outsourced employees",
            number: 300,
            cName:"counter third-count",
        },
    
    ]

    export default counterData;
