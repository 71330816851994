import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import sliderData from "./sliderData";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const CardSlider = () => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false, 
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false, // Remove dots for smallest screens
        },
      },
    ],
  };

  return (
    <div className="card-slider-container">
      <Slider ref={sliderRef} {...settings} className="custom-slide">
        {sliderData.map((item) => {
          const { text, id, service, img } = item;
          return (
            <div key={id} className={`slide-card ${id === 1 ? 'highlighted' : ''}`}>
              <img src={img} alt={img} />
              <h4>{service} </h4>
              <p> {text}</p>
            </div>
          );
        })}
      </Slider>
      <div className="buttons-container">
        <div
          className="prev-button"
          onClick={() => sliderRef.current.slickPrev()}
        >
          <FaArrowLeft />
        </div>
        <div
          className="next-button"
          onClick={() => sliderRef.current.slickNext()}
        >
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};

export default CardSlider;
