import React,{useEffect, useLayoutEffect, useRef, useState} from 'react';
import gsap from  "gsap";
import './Home.css';
import Navbar from '../../components/navbar/Navbar';
import Counter from '../../components/counter/Counter';
import Brand from '../../components/brand/Brand';
import ContactForm from '../../components/contactForm/ContactForm';
import Footer from '../../components/footer/Footer';
import CardSlider from '../../components/slider/Slider';
import Testimonials from '../../components/testimonials/Testimonials';
import MobileCards from '../../components/mobileCards/MobileCards';
import Head from '../../components/head/Head';

const Home = () => {
 
  return (
    <div>
        <Navbar/>
        <Head/>

        <Counter/>
        {/* services/ slide-card */}
        <main id='services' className='container'>
          <h3>Our Core Proficiencies</h3>
          <p className='sub-head'>Experience unrivaled quality</p>

          <div className="slide-wrapper">
            <CardSlider/>
          </div>

          <div className="mobile-cards-wrapper">
            <MobileCards/>
          </div>
        </main>

        <section id='about' className='container'>
          <h3>Who We Are</h3>
          <p className='sub-head'>More than consultants, we are your strategic partner</p>

          <p className='about-text'>At Careers Verified, we go beyond narrowly defined client’s issues, we  create opportunities for our clients, candidates, colleagues, collaborators,  and the community.  Through a network of diversified but highly complementary line of  business, we help organizations find critical talent, access cutting-edge  advice and get the innovative support they require to grow in a challenging  market.  We partner with businesses and organizations of varying size and  structure across diverse sectors, such as Oil and Gas, Engineering,  Finance, Telecom, FMCG, Legal, Technology, Healthcare, Travel and  Aviation etc. to enhance business performance and to optimize for growth  to achieve exceptional results. What sets us apart is the depth of our capabilities, creative problem solving approach and proven track record in our practice area combined  with in-depth knowledge of the realities of the industries we serve.</p>

          <div className="gallery">
            <img src="/assets/grouped-banner.png" alt="gallery" />
          
          </div>
        </section>

        <div className="three-col-container">
          <div className="left-col">
            <h4>Our Vision</h4>
            <p>To be recognized as an impactful, innovative and efficient  professional service firm delivering innovative and result oriented  human resource solutions that enables our client win in a dynamic  marketplace.</p>        
          </div>
          <div className="center-col">
          <img src="/assets/careers-verified-blue.svg" alt="" />
          </div>
          <div className="right-col">
            <h4>Our Mission</h4>
            <p>We are a professional, enthusiastic and innovative team dedicated  to provide professional HR Consulting Services that will create value  for our clients and achieve high performance in the marketplace.</p>
          </div>
        </div>
        <>
          <Testimonials/>
        </>

        {/* brands that trust us */}
        <>
          <Brand/> 
        </>
        <section className="form-container">
          <ContactForm/>
        </section>
        <>
          <Footer/>
        </>

    </div>
  )
}

export default Home