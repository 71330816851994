import React, { useState } from 'react'

const NavLinks = ({closeMenu}) => {
  const [activeLink, setActiveLink] = useState('home'); 
  const handleLinkClick = (link) => {
    setActiveLink(link); // Update active link state when a link is clicked
  };
  return (
    <div className='nav_links' onClick={closeMenu}>
        <ul>
            <li className={`navbar__item ${activeLink === 'home' ? 'active' : ''}`}>
              <a href="#home" onClick={() => handleLinkClick('home')} className="navbar__link">Home</a> 
            </li>
            <li className={`navbar__item ${activeLink === 'services' ? 'active' : ''}`}>
              <a href="#services" onClick={() => handleLinkClick('services')} className="navbar__link">Services</a>
            </li>
            <li className={`navbar__item ${activeLink === 'about' ? 'active' : ''}`}>
              <a href="#about" onClick={() => handleLinkClick('about')} className="navbar__link">About</a> 
            </li>
        </ul>
  
    </div>
  )
}

export default NavLinks