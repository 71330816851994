import React from "react";
import "./Counter.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import counterData from "./counterData";

const Counter = () => {
  return (
    <div className="counter-container">
      <div className="counter-head">
        <h2>Our Results Speak</h2>
        <p>Experience the impact firsthand</p>
      </div>

      <div className="counter-wrapper">
        {counterData.map(({ label, number, cName }) => {
          return (
            <div className="counter-content" key={number}>
              <VisibilitySensor>
                {({ isVisible }) => (
                  <div className={cName}>
                    <h2>
                      {isVisible ? <CountUp end={number} /> : null}
                      <span className="sign">+</span>
                      
                    </h2>
                  </div>
                )}
              </VisibilitySensor>
              <p>{label}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Counter;
